<template>
    <div style="background: linear-gradient(180deg, #E5EDFF 0%, #EDF1F7 100%);">
        <!-- <template v-show="!isLoading"> -->
        <div id="top-viewport" v-show="!isLoginPage"></div>
        <div class="main_right">
            <div class="main-box" :style="style">
                <div v-show="!isLoginPage && leftMenu.length > 0 && isShowMenu" id="menu-viewport"
                    :class="{ collapse: keyCollapse }">
                </div>
                <div class="main-viewport" :style="isLoginPage ? 'height:100%' : ''">
                    <div id="subapp-viewport" :style="viewport"></div>
                </div>
            </div>
        </div>
        <!-- </template>
<loading :loading="isLoading" /> -->
    </div>
</template>

<script>
import microApps from './micro-app'
import { loadMicroApp } from 'qiankun';
import store from './store'
export default {
    name: 'App',
    data() {
        return {
            isLoginPage: true,
            isLoading: true,
            keyCollapse: false,
            microApps,
            current: '/',
            microApp: null,
            microAppleft: null,
            isfristLoad: false,
            leftMenu: [],
            darkReader: null,
            timer: null
        }
    },
    mounted() {
        const _this = this
        this.listenRouterChange()
        // _this.loadMicroAppLeftFun()
        // _this.loadMicroAppFun()
        store.onGlobalStateChange((state, pre) => {
            _this.leftMenu = state.leftMenu
            if (state.isLoginPage != pre.isLoginPage) {
                _this.isLoginPage = state.isLoginPage
                if (!state.isLoginPage) {
                    _this.loadMicroAppFun()
                    _this.loadMicroAppLeftFun()
                } else {
                    _this.microApp?.unmount()
                    _this.microAppleft?.unmount()
                }
                // if (!state.isLoginPage) {
                //     if (_this.timer) {
                //         clearTimeout(_this.timer)
                //         _this.timer = null
                //     }
                //     _this.$nextTick(() => {
                //         _this.timer = setTimeout(() => {

                //             clearTimeout(_this.timer)
                //             _this.timer = null
                //         }, 500);
                //     })
                // } else {
                //     _this.microApp?.unmount()
                //     _this.microAppleft?.unmount()
                // }
            }
            if (state.setDark != pre.setDark) {
                _this.setDarkMode(state.setDark)
            }
            if (state.keyCollapse != pre.keyCollapse) {
                _this.keyCollapse = state.keyCollapse
            }
        }, false)
        const setDarkType = store.getGlobalState('setDark')
        this.setDarkMode(setDarkType)
    },
    beforeDestroy() {
        this.microApp?.unmount()
        this.microAppleft?.unmount()
        console.log("🚀 ~ beforeDestroy ~ this.microApp:", this.microApp)
        alert('销毁')
    },
    computed: {
        style() {
            if (this.isLoginPage || this.leftMenu.length == 0 || !this.isShowMenu) {
                return 'display:block'
            } else {
                return `grid-template-columns:  ${this.keyCollapse ? '64px' : '220px'} auto;`
            }
        },
        isShowMenu() {
            if (process.env.NODE_ENV == "development") {
                return true

            }
            console.log("🚀 ~ isShowMenu ~ this.$route.hash:", this.$route.hash)
            return !this.$route.hash.includes('/wel/index') && !this.$route.hash.includes('#/info/index')
        },
        viewport(){
            if(this.$route.hash.includes('#/license/client')){
                return 'padding:20px 10px'
            }else{
                return ''
            }
        }
    },
    methods: {
        //#region 手动加载app
        loadMicroAppFun() {
            let origin = ''
            const hash = location.hash
            if (hash.includes('/login')) { return }
            if (process.env.NODE_ENV !== 'development') {
                origin = window.location.origin
            }
            this.microApp = loadMicroApp(
                {
                    name: 'top-menu-micro',
                    entry: origin + process.env.VUE_APP_TOP_MENU_MICRO,
                    container: '#top-viewport', // 子应用挂载的div
                    activeRule: () => true,
                    props: {
                        getGlobalState: store.getGlobalState // 下发getGlobalState方法
                    }
                },
            )
            console.log("🚀 ~ loadMicroAppFun ~ this.microApp:", this.microApp)
        },
        loadMicroAppLeftFun() {
            let origin = ''
            const hash = location.hash
            if (hash.includes('/login')) { return }
            if (process.env.NODE_ENV !== 'development') {
                origin = window.location.origin
            }
            console.log("🚀 ~ loadMicroAppLeftFun ~ this.microAppleft:", this.microAppleft)
            this.microAppleft = loadMicroApp({
                name: 'menu-micro',
                entry: origin + process.env.VUE_APP_MENU_MICRO,
                container: '#menu-viewport', // 子应用挂载的div
                activeRule: () => true,
                props: {
                    getGlobalState: store.getGlobalState // 下发getGlobalState方法
                }
            },)
        },
        //#endregion
        goto(item) {
            history.pushState(null, item.activeRule, item.activeRule)
            this.current = item.name
        },
        bindCurrent() {
            const path = window.location.pathname
            if (this.microApps.findIndex(item => item.activeRule === path) >= 0) {
                this.current = path
            }
        },
        listenRouterChange() {
            const _wr = function (type) {
                const orig = history[type]
                return function () {
                    const rv = orig.apply(this, arguments)
                    const e = new Event(type)
                    e.arguments = arguments
                    window.dispatchEvent(e)
                    return rv
                }
            }
            history.pushState = _wr('pushState')

            window.addEventListener('pushState', this.bindCurrent)
            window.addEventListener('popstate', this.bindCurrent)

            this.$once('hook:beforeDestroy', () => {
                window.removeEventListener('pushState', this.bindCurrent)
                window.removeEventListener('popstate', this.bindCurrent)
            })
        },
        setDarkMode(type) {
            if (type) {
                // 给html标签加dark样式属性
                document.documentElement.classList.add('dark');
                this.darkReader.enable({
                    brightness: 100,
                    contrast: 95,
                    sepia: 20
                });
            } else {
                document.documentElement.classList.remove('dark');
                this.darkReader.disable();
            }
        }
    },
    created() {
        this.darkReader = eval("DarkReader")
        this.darkReader.setFetchMethod(window.fetch)
        this.bindCurrent()
        // NProgress.start()
    },
}
</script>
<style lang="scss" scoped>
.collapse {
    width: 64px !important;
}

.main_right {
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    overflow: auto;
    background: linear-gradient(180deg, #E5EDFF 0%, #EDF1F7 100%);

    .main-box {
        display: grid;

    }
}

#top-viewport {
    position: sticky;
    top: 0;
    transition: all 0.3s;
    z-index: 2001;
}

.main-viewport {
    height: calc(100vh - 60px);
    overflow: auto;
    width: 100%;
    transition: all 0.3s;
}

#subapp-viewport {


    &:first-child {
        height: 100%
    }
}

#menu-viewport {
    transition: all 0.3s;
    height: calc(100vh - 60px);
    overflow: auto;
    background: linear-gradient(180deg, #E5EDFF 0%, #EDF1F7 100%);

    &:first-child {
        height: 100%
    }
}
</style>
